/** @format */

import {useEffect, useState, useCallback, useRef} from 'react';

import {useApp} from '@context/AppProvider';
import {api} from '@utils/api';

function TopHeader(props) {
	const {styles} = props;
	const headBarRef = useRef({});
	const {
		idb: {session},
	} = useApp();

	const [topHeader, settopHeader] = useState({
		backgroundColor: null,
		textEditor: null,
		textColor: null,
	});

	const fetchTopHeader = useCallback(async () => {
		if (session) {
			try {
				const result = await api(
					{
						url: `/settings/get-top-header`,
						method: 'POST',
					},
					session,
				);
				if (result) {
					settopHeader({
						backgroundColor: result.background_color
							? result.background_color
							: null,
						textEditor: result.text_editor ? result.text_editor : null,
						textColor: result.text_color ? result.text_color : null,
					});
				}
			} catch (err) {
				throw Error(err);
			}
		}
	}, [session]);

	useEffect(() => {
		fetchTopHeader();
	}, [fetchTopHeader, session]);

	const scrollTop = useCallback(() => {
		window.addEventListener('scroll', function () {
			let bodyClass = document.getElementsByClassName('headerScrollFix');
			let element = document.getElementById('top-header');
			if (element) {
				if (bodyClass.length === 0 && window.scrollY >= 50) {
					element.style.top = `-${
						headBarRef?.current?.main?.offsetHeight || 40
					}px`;
				} else {
					element.style.top = '0px';
				}
			}
		});
	}, []);
	useEffect(() => {
		try {
			if (topHeader?.textEditor && topHeader?.textEditor.isEditor) {
				if (headBarRef?.current.left && headBarRef?.current.left?.lastChild) {
					for (let item of headBarRef?.current.left.children)
						item.style.color = topHeader.textColor;
				}
				if (
					headBarRef?.current.center &&
					headBarRef?.current.center?.lastChild
				) {
					for (let item of headBarRef?.current.center.children)
						item.style.color = topHeader.textColor;
				}
				if (headBarRef?.current.right && headBarRef?.current.right?.lastChild) {
					for (let item of headBarRef?.current.right.children)
						item.style.color = topHeader.textColor;
				}
				if (headBarRef?.current.left?.style) {
					headBarRef.current.left.style.color = topHeader.textColor;
				}
				if (headBarRef?.current.center?.style) {
					headBarRef.current.center.style.color = topHeader.textColor;
				}
				if (headBarRef?.current.right?.style) {
					headBarRef.current.right.style.color = topHeader.textColor;
				}
			}
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}, [topHeader]);

	useEffect(() => {
		scrollTop();
	}, [scrollTop]);

	return (
		topHeader &&
		topHeader.backgroundColor &&
		topHeader.textEditor && (
			<div
				ref={ref => (headBarRef.current['main'] = ref)}
				style={{
					backgroundColor: `${topHeader.backgroundColor}`,
				}}
				id='top-bar-links'
				className={`${styles.topHeader} ${styles.pistachioBg}`}
			>
				{topHeader.textEditor.isEditor === 'true' ? (
					<ul className='d-flex align-items-center justify-content-between'>
						<li
							ref={ref => (headBarRef.current['left'] = ref)}
							dangerouslySetInnerHTML={{
								__html: topHeader.textEditor.left,
							}}
						></li>
						<li
							className='text-center'
							ref={ref => (headBarRef.current['center'] = ref)}
							dangerouslySetInnerHTML={{
								__html: topHeader.textEditor.center,
							}}
						></li>
						<li
							className='text-right'
							ref={ref => (headBarRef.current['right'] = ref)}
							dangerouslySetInnerHTML={{
								__html: topHeader.textEditor.right,
							}}
						></li>
					</ul>
				) : (
					<ul className='d-flex align-items-center justify-content-between'>
						<li ref={ref => (headBarRef.current['left'] = ref)}>
							{topHeader.textEditor?.leftLink ? (
								<a
									rel='noreferrer'
									target='_blank'
									href={topHeader.textEditor.leftLink}
								>
									{topHeader.textEditor?.left}
								</a>
							) : (
								topHeader.textEditor?.left
							)}
						</li>
						<li
							className='text-center'
							ref={ref => (headBarRef.current['center'] = ref)}
						>
							{topHeader.textEditor?.centerLink ? (
								<a
									rel='noreferrer'
									target='_blank'
									href={topHeader.textEditor.centerLink}
								>
									{topHeader.textEditor?.center}
								</a>
							) : (
								topHeader.textEditor?.center
							)}
						</li>
						<li
							className='text-right'
							ref={ref => (headBarRef.current['right'] = ref)}
						>
							{topHeader.textEditor?.rightLink ? (
								<a
									rel='noreferrer'
									target='_blank'
									href={topHeader.textEditor.rightLink}
								>
									{topHeader.textEditor?.right}
								</a>
							) : (
								topHeader.textEditor?.right
							)}
						</li>
					</ul>
				)}
			</div>
		)
	);
}

export default TopHeader;
